import { Controller } from '@hotwired/stimulus';
import { rollbar } from 'lib/rollbar';

export default class extends Controller {
  select({ target }): void {
    const [beginDateTime, endDateTime] = target.dataset.datetimeRange.split('_');
    const params = {
      mode: 'selectedDateTime',
      bookBoardId: target.dataset.id,
      componentId: target.dataset.componentId,
      bookboardTimezone: target.dataset.bookboardTimezone,
      timezone: target.dataset.timezone,
      beginDateTime,
      endDateTime,
      meetingStyle: target.dataset.meetingStyle,
      meetingPlace: target.dataset.meetingPlace,
    };
    console.log(params);
    window.parent.postMessage(params, '*');
    rollbar.info('formrunに埋め込んでいるブックボードの予約パラメータ', { params: params });
  }
}
